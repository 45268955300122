export const dateInfo = date => ({
    date,
    name: date.toLocaleString('en-US', { weekday: 'long' }),
    str: date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).split(',').join(' /')
});

export const sundayDate = () => {
    const d = new Date();
    const date = new Date(new Date(d.setDate(d.getDate() - d.getDay())).toDateString());
    return dateInfo(date);
};

export const nextDate = date => {
    const d = new Date(date);
    const next = new Date(d.setDate(d.getDate() + 1));
    return dateInfo(next);
};

const addZero = num => `${num < 10 ? '0' : ''}${num}`;

export const display = {
    time: (hours, minutes, seconds) => `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`,

    dateTime: date => display.time(date.getHours(), date.getMinutes(), date.getSeconds()),

    interval: (start, end) => `${display.dateTime(start)} - ${display.dateTime(end)}`,

    diff: (start, end) => {
        const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
        const seconds = diffInSeconds % 60;
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const hours = Math.floor(seconds / 3600);
        return display.time(hours, minutes, seconds);
    }
};
