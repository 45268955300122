import { useState, useEffect, useContext } from 'react';
import { Avatar, Box, Button, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import context from '../context';
import style from '../style';
import { dateInfo, display, nextDate, sundayDate } from './time.const';
import api from '../api';
import { avaPath, strStart } from '../resources';

export default () => {
    const { creds, me } = useContext(context);

    const [timeTable, setTimeTable] = useState([]);

    const [start, setStart] = useState(sundayDate());

    const handle = {
        move: {
            prev: () => {
                const d = new Date(start.date);
                const prev = new Date(d.setDate(d.getDate() - 7));
                setStart(dateInfo(prev));
            },

            current: () => setStart(sundayDate()),

            next: () => {
                const d = new Date(start.date);
                const next = new Date(d.setDate(d.getDate() + 7));
                setStart(dateInfo(next));
            }
        }
    };

    useEffect(() => {
        getTimeTable();
    }, [me, start]);

    const getTimeTable = async () => {
        const d = new Date(start.date);
        const endDate = new Date(d.setDate(d.getDate() + 14));
        const calls = (await api.get.call(creds, start.date, endDate)).filter(call => call.end).map(call => {
            call.startOut = new Date(call.startOut);
            if (call.startIn) call.startIn = new Date(call.startIn);
            call.end = new Date(call.end);
            return call;
        });

        const week = [];
        let day = start;

        for (let k = 0; k < 14; k++) {
            const dayCalls = [];
            for (const call of calls) if (day.date < call.startOut && call.startOut < nextDate(day.date).date) dayCalls.push(call);

            const clr = {
                back: 'dimgray',
                call: ['forestgreen', 'darkgreen'],
                missed: ['mediumblue', 'midnightblue'],
                declined: ['firebrick', 'darkred']
            };

            const listItem = (call, last) => {
                const { person, missed, declined, out, startOut, startIn, end } = call;
                const color = (missed ? clr.missed : declined ? clr.declined : clr.call)[Number(out)];

                return <div style={style.bgc(color)}>
                    <ListItem style={style.flex('center')}>
                        <Avatar style={style.bd} src={avaPath(person.id)}/>
                    </ListItem>
                    <ListItem style={style.flex('center')}>
                        <Typography variant="caption" style={style.center()}>
                            {strStart(person.firstName, 15)}<br/>
                            {strStart(person.lastName, 15)}<br/>
                            - - - - - - -<br/>
                            {out ? 'Outgoing' : 'Incoming'}<br/>
                            {missed ? 'Missed' : declined ? 'Declined' : `Lasted ${display.diff(startIn, end)}`}<br/>
                            {missed || declined ? display.dateTime(startOut) : display.interval(startIn, end)}
                        </Typography>
                    </ListItem>
                    {last ? <></> : <ListItem style={style.bgc(clr.back)}/>}
                </div>;
            };

            const list = [];
            for (let i = 0; i < dayCalls.length; i++) list.push(listItem(dayCalls[i], i == dayCalls.length - 1));

            if (!(k % 7)) week.push(<Grid xs={1}/>);
            week.push(<>
                <Grid xs={1.42}><List>
                    <ListItem><ListItemText style={style.center()}>{day.name}<br/>{day.str}</ListItemText></ListItem>
                    <div style={style.scroll(320, clr.back)}>{list}</div>
                </List></Grid>
                <Grid xs={0.01}/>
            </>);
            if (!((k + 1) % 7)) week.push(<Grid xs={0.99}/>);

            day = nextDate(day.date);
        }

        setTimeTable(week);
    };

    const paginator = <>
        <div style={style.flex('center')}>
            <Button color="inherit" variant="outlined" style={style.color.green.dark} onClick={handle.move.prev} startIcon={<NavigateBefore/>}>prev</Button>
            <Button color="inherit" variant="outlined" style={start.date.getTime() == sundayDate().date.getTime() ? style.color.green.light : style.color.green.dark} onClick={handle.move.current}>current</Button>
            <Button color="inherit" variant="outlined" style={style.color.green.dark} onClick={handle.move.next} endIcon={<NavigateNext/>}>next</Button>
        </div>
    </>;

    return <>{paginator}<Box><Grid container>{timeTable}</Grid></Box></>;
};
