import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemText, TextField } from '@mui/material';
import { PhoneTwoTone, Search } from '@mui/icons-material';
import api from '../api';
import context from '../context';
import style from '../style';
import { absCenter, avatar, divider, type } from '../resources';

const SearchBar = ({ fetchData }) => {
    const [searchText, setSearchText] = useState('');

    const handle = {
        change: e => type(e, 'Search Text', setSearchText, 255),
        keyDown: e => e.key == 'Enter' && handle.click(),
        click: async () => await fetchData({ search: searchText })
    };

    return <div style={style.flex('space-between', 'center')}>
        <TextField fullWidth sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label="Type here to search..." value={searchText} onChange={handle.change} onKeyDown={handle.keyDown}/>
        <Button style={style.squareBtn} onClick={handle.click}><Search htmlColor="greenyellow"/></Button>
    </div>;
};

export default () => {
    const { socket, me, goCall } = useContext(context);
    const [users, setUsers] = useState([]);

    const fetchData = async params => {
        const data = await api.get.all(params);
        setUsers(data.rows.filter(user => user.id != me.id));
    };

    useEffect(() => { me.userName && fetchData(); }, [me]);

    const textColor = (text, color) => <div style={{ ...style.clr(color), display: 'inline' }}>{text}</div>;

    const PersonItem = ({ person }) => {
        const { id, userName, firstName, lastName } = person;
        const [online, setOnline] = useState(false);

        socket.on(`online#${userName}`, setOnline);
        socket.emit('online#', userName);

        return <>
            <ListItem>
                <Button style={style.squareBtn} onClick={goCall(userName)}><PhoneTwoTone htmlColor="greenyellow"/></Button>
                <ListItemButton>
                    {avatar(id, online)}
                    <ListItemText>{firstName} {lastName} {textColor('[', 'forestgreen')} {textColor(`@${userName}`, 'greenyellow')} {textColor(']', 'forestgreen')}</ListItemText>
                </ListItemButton>
            </ListItem>
        </>;
    };

    const personList = () => users.map((person, i) => <>{divider(i)}<PersonItem person={person}/></>);

    return <>
        <Box><Grid container>
            <Grid xs={1}/>
            <Grid xs={7}>
                <SearchBar fetchData={fetchData}/>
                <div style={style.scroll(400)}><List>{personList()}</List></div>
            </Grid>
            <Grid xs={4}/>
        </Grid></Box>
        {users.length ? <></> : absCenter('Users Not Found')}
    </>;
};
