import axios from 'axios';

const api = axios.create({ baseURL: '/api/' });

const req = async fun => {
    try {
        const res = await fun();
        return res.data.result;
    } catch (err) { console.log(err); }
};

const API = {
    report: async text => await req(() => api.post('report', { text })),

    home: {
        get: async () => await req(() => api.get('home')),
        update: async (creds, text) => await req(() => api.put('home', { creds, text }))
    },

    get: {
        all: async params => {
            let query = '?';
            if (params) for (const key of Object.keys(params)) query += `${key}=${params[key]}&`;
            query = query.slice(0, -1);
            return await req(() => api.get(query));
        },

        one: async userName => await req(() => api.get(userName)),

        iceServers: async creds => await req(() => api.post('ice-servers', { creds })),

        call: async (creds, start, end) => await req(() => api.post('call', { creds, start, end }))
    },

    person: {
        get: async creds => await req(() => api.post('me', { creds })),
        create: async data => await req(() => api.post('', data)),
        update: async data => await req(() => api.put('', data)),
        delete: async creds => await req(() => api.delete('', { creds })),
        resend: async creds => await req(() => api.patch('resend', { creds })),
        verify: async (creds, code) => await req(() => api.patch('verify', { creds, code })),
        reset: {
            a: async email => await req(() => api.patch('reset/a', { email })),
            b: async (userName, code) => await req(() => api.patch('reset/b', { userName, code }))
        }
    }
};

export default API;
