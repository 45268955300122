import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ContextProvider } from './context';
import Header from './components/header';
import Home from './components/home';
import Verify from './components/verify';
import Reset from './components/reset';
import Sign from './components/sign';
import Calendar from './components/calendar';
import Users from './components/users';
import Profile from './components/profile';
import Call from './components/call';

import process from 'process';
import { Buffer } from 'buffer';
window.process = process;
window.Buffer = Buffer;

ReactDOM.createRoot(document.getElementById('root')).render(<>
    <ContextProvider>
        <Header/>
        <BrowserRouter><Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/verify/:code" element={<Verify/>}/>
            <Route exact path="/reset/:userName/:code" element={<Reset/>}/>
            <Route exact path="/calendar" element={<Sign Component={Calendar}/>}/>
            <Route exact path="/users" element={<Sign Component={Users}/>}/>
            <Route exact path="/profile" element={<Profile/>}/>
            <Route exact path="/call/:userName" element={<Call/>}/>
        </Routes></BrowserRouter>
    </ContextProvider>
</>);
