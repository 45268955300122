import { useState, useEffect, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Avatar, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { ErrorTwoTone, GppBadTwoTone, GppGoodTwoTone, LocalPoliceTwoTone } from '@mui/icons-material';
import api from '../api';
import context from '../context';
import style from '../style';
import { avaPath, go, hash, recaptchaSiteKey, type } from '../resources';

export default () => {
    const { creds, me, set } = useContext(context);

    const [imageDeleted, setImageDeleted] = useState(true);
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [deleteImageDisabled, setDeleteImageDisabled] = useState(true);
    const [emailResent, setEmailResent] = useState(false);

    const [image, setImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [secretPassword, setSecretPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [captcha, setCaptcha] = useState('');

    const [reserved, setReserved] = useState([]);

    const chars = [[48, 10], [65, 26], [97, 26]].map(
        ([start, length]) => [...Array(length)].map((_, i) => i + start).map(x => String.fromCharCode(x)).join('')
    ).join('');

    const allowed = () => userName.split('').reduce((v, i) => v && `${chars}_-`.includes(i), true);

    useEffect(() => {
        if (me.id) {
            setImageDeleted(false);
            setFirstName(me.firstName);
            setLastName(me.lastName);
            setEmail(me.email);
            setUserName(me.userName);
        }
    }, [me]);

    useEffect(() => {
        setDeleteImageDisabled(!image && imageDeleted);
    }, [image, imageDeleted]);

    useEffect(() => {
        setConfirmDisabled(
            !me.id && !captcha ||
            !firstName || !lastName || !email ||
            !userName || userName.length < 3 || reserved.includes(userName) || !allowed() ||
            !creds.userName && !secretPassword || secretPassword && secretPassword.length < 8 || secretPassword != repeatPassword
        );
    }, [captcha, firstName, lastName, email, userName, secretPassword, repeatPassword, reserved]);

    const label = {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        userName: 'User Name',
        secretPassword: 'Secret Password',
        repeatPassword: 'Repeat Password'
    };

    const handle = {
        image: {
            upload: e => {
                e.preventDefault();
                const file = e.target.files[0];
                const allowed = ['.jpg', '.jpeg'];

                if (allowed.map(extension => file.name.toLowerCase().endsWith(extension)).includes(true)) {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        setImage(reader.result);
                        setImageDeleted(true);
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert(`Allowed extensions: ${allowed.join(' | ')}`);
                }
            },

            delete: () => {
                setImage('');
                setImageDeleted(true);
            }
        },

        type: {
            firstName: e => type(e, label.firstName, setFirstName, 255),
            lastName: e => type(e, label.lastName, setLastName, 255),
            email: e => type(e, label.email, setEmail, 255),
            userName: e => type(e, label.userName, setUserName, 255),
            secretPassword: e => type(e, label.secretPassword, setSecretPassword, 255),
            repeatPassword: e => type(e, label.repeatPassword, setRepeatPassword, 255)
        },

        confirm: async () => {
            if (creds.userName != userName && await api.get.one(userName)) {
                setReserved([...reserved, userName]);
            } else {
                const password = secretPassword ? hash(secretPassword) : creds.password;
                const person = { image, firstName, lastName, email, userName, password };

                creds.userName
                    ? await api.person.update({ ...person, creds, imageDeleted })
                    : await api.person.create({ ...person, captcha });

                await set.me.login({ userName, password }) ? go()() : alert('Oops! Something went wrong.');
            }
        },

        resend: async () => {
            await api.person.resend(creds);
            setEmailResent(true);
        }
    };

    const clr = {
        admin: 'gold',
        verified: 'lime',
        notVerified: 'orangered',
        error: 'orange'
    };

    const statusText = (clr, text) => <Typography variant="overline" fontWeight="bold" color={clr}>{text}</Typography>;

    const error = text => <>
        {statusText(clr.error, text)}
        <ErrorTwoTone htmlColor={clr.error}/>
    </>;

    const status = {
        email: () => me.id ? me.admin ? <>
            {statusText(clr.admin, 'admin')}
            <LocalPoliceTwoTone htmlColor={clr.admin}/>
        </> : me.verified ? <>
            {statusText(clr.verified, 'verified')}
            <GppGoodTwoTone htmlColor={clr.verified}/>
        </> : <div style={{ ...style.column, ...style.flex() }}>
            <div style={style.flex('left', 'center')}>
                {statusText(clr.notVerified, 'not verified')}
                <GppBadTwoTone htmlColor={clr.notVerified}/>
            </div>
            {emailResent
                ? <Typography variant="caption">{'Check out your Email'}</Typography>
                : <Typography variant="caption" style={style.link} onClick={handle.resend}>{'Resend verification email'}</Typography>}
        </div> : <></>,

        userName: () => userName ? allowed() ? userName.length < 3 ? error('too short') : reserved.includes(userName) ? error('reserved') : <></> : <Typography variant="caption" style={style.clr('orange')}>
            <div>Allowed characters:</div>
            <div>A...Z | a...z | 0...9 | _ | -</div>
        </Typography> : <></>,

        password: () => secretPassword ? secretPassword.length < 8 ? error('too short')
            : repeatPassword && secretPassword != repeatPassword ? error('mismatch') : <></> : <></>
    };

    return <>
        <Box><Grid container>
            <Grid xs={6}><Grid container>
                <Grid xs={12}><Avatar style={{ ...style.float('right'), ...style.margin.ava }} src={imageDeleted ? image : avaPath(me.id)} onError={() => setImageDeleted(true)}/></Grid>
                <Grid xs={12}><Button color="inherit" variant="outlined" style={{ ...style.float('right'), ...style.color.green.dark }} onClick={() => document.getElementById('image-upload').click()}>
                    {deleteImageDisabled ? 'upload image' : 'change image'}
                    <input key={Math.random()} style={style.none} id="image-upload" onChange={handle.image.upload} type="file" accept="image/jpeg"/>
                </Button></Grid>
                <Grid xs={12}><Button color="inherit" variant="outlined" style={{ ...style.float('right'), ...(deleteImageDisabled ? style.color.gray : style.color.red) }} onClick={handle.image.delete} disabled={deleteImageDisabled}>delete image</Button></Grid>
            </Grid></Grid>
            <Grid xs={6}><Grid container>
                <Grid xs={12}><TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label={label.firstName} value={firstName} onChange={handle.type.firstName}/></Grid>
                <Grid xs={12}><TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label={label.lastName} value={lastName} onChange={handle.type.lastName}/></Grid>
                <Grid xs={12}>
                    <div style={style.flex('left', 'center')}>
                        <TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label={label.email} value={email} onChange={handle.type.email}/>
                        {status.email()}
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div style={style.flex('left', 'center')}>
                        <TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label={label.userName} value={userName} onChange={handle.type.userName}/>
                        {status.userName()}
                    </div>
                </Grid>
            </Grid></Grid>
        </Grid></Box>
        <Box><Grid container>
            <Grid xs={6}><TextField sx={style.sx} InputProps={style.inputProps} type="password" variant="outlined" style={style.margin.float('right')} label={label.secretPassword} value={secretPassword} onChange={handle.type.secretPassword}/></Grid>
            <Grid xs={6}>
                <div style={style.flex('left', 'center')}>
                    <TextField sx={style.sx} InputProps={style.inputProps} type="password" variant="outlined" style={style.mg} label={label.repeatPassword} value={repeatPassword} onChange={handle.type.repeatPassword}/>
                    {status.password()}
                </div>
            </Grid>
        </Grid></Box>
        {me.id ? <></> : <Box><center>
            <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={setCaptcha} onExpired={() => setCaptcha('')} theme="dark"/>
        </center></Box>}
        <Box><center>
            <Button color="inherit" variant="outlined" style={confirmDisabled ? style.color.gray : style.color.green.light} onClick={handle.confirm} disabled={confirmDisabled}>confirm</Button>
        </center></Box>
    </>;
};
