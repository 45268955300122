import React, { useEffect, useRef } from 'react';
import { display } from './time.const';

const style = document.createElement('style');
style.innerHTML = `@font-face {
    font-family: "Digital-7";
    src: url("${process.env.PUBLIC_URL}/digital-7.ttf") format("truetype");
}`;
document.head.appendChild(style);

export default () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const radius = canvas.width / 2;

        const drawClock = () => {
            const now = new Date();
            const seconds = now.getSeconds();
            const minutes = now.getMinutes();
            const hours = now.getHours();

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.strokeStyle = 'forestgreen';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.arc(radius, radius, radius - 10, 0, Math.PI * 2);
            ctx.stroke();

            ctx.strokeStyle = 'yellow';
            for (let i = 0; i < 12; i++) {
                const angle = (Math.PI / 6) * i;
                const x1 = radius + (radius - 15) * Math.cos(angle);
                const y1 = radius + (radius - 15) * Math.sin(angle);
                const x2 = radius + (radius - 5) * Math.cos(angle);
                const y2 = radius + (radius - 5) * Math.sin(angle);
                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();
            }

            ctx.fillStyle = 'limegreen';
            ctx.font = '80px Digital-7';
            ctx.textAlign = 'center';
            let time = display.time(hours, minutes, seconds);

            if (hours == 3 && minutes == 59 && seconds >= 54) {
                const colors = ['gold', 'darkorange', 'orangered', 'deeppink', 'royalblue', 'aqua'];
                const ms = (seconds - 54) * 1000 + now.getMilliseconds();
                let x = 1000;
                let t = ms;

                if (ms < 3000) {
                    x /= 2;
                } else if (ms < 5000) {
                    x /= 3;
                    t -= 3000;
                } else {
                    x /= 6;
                    t -= 5000;
                }

                if (t % x < x / 2) time = ''; else ctx.fillStyle = colors[Math.floor(t / x)];
            }

            ctx.fillText(time, radius, radius + 20);

            const secondsAngle = (Math.PI * 2) * (seconds / 60) - Math.PI / 2;
            const secX = radius + (radius - 20) * Math.cos(secondsAngle);
            const secY = radius + (radius - 20) * Math.sin(secondsAngle);

            ctx.strokeStyle = 'yellowgreen';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(radius, radius);
            ctx.lineTo(secX, secY);
            ctx.stroke();

            requestAnimationFrame(drawClock);
        };

        requestAnimationFrame(drawClock);

        return () => ctx.clearRect(0, 0, canvas.width, canvas.height);
    }, []);

    return <canvas ref={canvasRef} width={400} height={400} style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '20px',
        borderRadius: '50%'
    }}></canvas>;
};
