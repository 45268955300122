import { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Avatar, Button, Dialog, DialogTitle, Fab, List, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { CallEndTwoTone, MicTwoTone, MicOffTwoTone, VideocamTwoTone, VideocamOffTwoTone, RingVolumeTwoTone, ScreenShareTwoTone, StopScreenShareTwoTone, FullscreenTwoTone, FullscreenExitTwoTone, HdTwoTone, VolumeUpTwoTone, VolumeDownTwoTone, VolumeOffTwoTone, VisibilityTwoTone, VisibilityOffTwoTone, HandshakeTwoTone, NotificationsActiveTwoTone, SendTwoTone, CloseTwoTone, ArrowDownwardTwoTone } from '@mui/icons-material';
import Peer from 'simple-peer';
import io from 'socket.io-client';
import api from '../api';
import context from '../context';
import style from '../style';
import { absCenter, avaPath, br, btn, debug, divider, getReady, randomNum, resolutionList, strStart } from '../resources';

const socket = io('/call');
const chatSocket = io('/chat');

const createSilentAudioTrack = () => {
    const audioContext = new AudioContext();
    const oscillator = audioContext.createOscillator();
    const dst = oscillator.connect(audioContext.createMediaStreamDestination());
    oscillator.start();
    const track = dst.stream.getAudioTracks()[0];
    return Object.assign(track, { enabled: false });
};

export default () => {
    const { userName } = useParams();
    const { creds } = useContext(context);
    const terminalDisplayRef = useRef({});

    const [audio, setAudio] = useState(true);
    const [audioPerson, setAudioPerson] = useState(false);
    const [video, setVideo] = useState(false);
    const [videoPerson, setVideoPerson] = useState(false);
    const [screen, setScreen] = useState(false);
    const [screenPerson, setScreenPerson] = useState(false);

    const [ready, setReady] = useState(false);

    const [full, setFull] = useState(false);
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);
    const [auth, setAuth] = useState(false);

    const [done, setDone] = useState(false);
    const [ended, setEnded] = useState(false);
    const [reload, setReload] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [notAuthed, setNotAuthed] = useState(false);
    const [intercepted, setIntercepted] = useState(false);
    const [notIntercepted, setNotIntercepted] = useState(false);

    const [audioButtonEnabled, setAudioButtonEnabled] = useState(true);
    const [videoButtonEnabled, setVideoButtonEnabled] = useState(true);
    const [screenButtonEnabled, setScreenButtonEnabled] = useState(true);

    const [now, setNow] = useState(0);
    const [localTime, setLocalTime] = useState('');
    const [mouse, setMouse] = useState(0);
    const [timer, setTimer] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [person, setPerson] = useState({});

    const [cursor, setCursor] = useState(null);
    const [display, setDisplay] = useState(null);

    const [userStreamPerson, setUserStreamPerson] = useState(null);
    const [displayStreamPerson, setDisplayStreamPerson] = useState(null);
    const [peer, setPeer] = useState(null);
    const [userStream, setUserStream] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);
    const [videoTrack, setVideoTrack] = useState(null);
    const [displayStream, setDisplayStream] = useState(null);

    const [alertText, setAlertText] = useState('');
    const [objectFit, setObjectFit] = useState('contain');

    const initResolution = window.localStorage.getItem('resolution') || '1280 x 720';
    const [selectedResolution, setSelectedResolution] = useState(initResolution);
    const [resolution, setResolution] = useState(initResolution.split(' x '));
    const [resolutionDialogOpen, setResolutionDialogOpen] = useState(false);
    const [interceptDialogOpen, setInterceptDialogOpen] = useState(false);
    const [terminalDialogOpen, setTerminalDialogOpen] = useState(false);
    const [toggleTerminal, setToggleTerminal] = useState(false);
    const [enter, setEnter] = useState(false);
    const [receive, setReceive] = useState(null);
    const [chatText, setChatText] = useState('');
    const [messageText, setMessageText] = useState('');
    const [scrolled, setScrolled] = useState(true);
    const [latestMessage, setLatestMessage] = useState(null);
    const [currentScroll, setCurrentScroll] = useState(0);
    const [waitTerminalLoadEnd, setWaitTerminalLoadEnd] = useState(true);

    const defaultRatio = 0.5625;
    const [callerRatio, setCallerRatio] = useState(defaultRatio);

    const defaultHeight = 135;
    const defaultResolution = [defaultHeight / defaultRatio, defaultHeight];
    const [callerResolution, setCallerResolution] = useState(defaultResolution);
    const [screenResolution, setScreenResolution] = useState(defaultResolution);
    const [reflexResolution, setReflexResolution] = useState(defaultResolution);

    const [audioPersonVolume, setAudioPersonVolume] = useState(100);
    const [screenPersonVolume, setScreenPersonVolume] = useState(100);
    const [displayAudioPerson, setDisplayAudioPerson] = useState(false);

    const [callerVisible, setCallerVisible] = useState(true);

    const buzzTimeout = 2000;
    const [buzzEnabled, setBuzzEnabled] = useState(true);
    const [buzz, setBuzz] = useState(false);

    const handshakeTimeout = 5000;
    const [handshakeEnabled, setHandshakeEnabled] = useState(true);
    const transition = `${handshakeTimeout * 0.25}ms`;

    const initScale = 0.2;
    const danceScale = 1;
    const [scale, setScale] = useState(initScale);
    const [opacity, setOpacity] = useState(0);

    const scrollToEnd = () => {
        if (terminalDisplayRef.current) terminalDisplayRef.current.scrollTop = terminalDisplayRef.current.scrollHeight;
    };

    const pad = x => `${x > 9 ? '' : '0'}${x}`;

    const clock = t => {
        t = t - t % 1;
        const s = t % 60;
        const m = (t - s) / 60 % 60;
        const h = (t - m * 60 - s) / 3600 % 24;
        const d = (t - h * 3600 - m * 60 - s) / 86400;
        return `${d ? `${d}d ` : ''}${pad(h)}:${pad(m)}:${pad(s)}`;
    };

    const src = (elementId, srcObject) => document.getElementById(elementId).srcObject = srcObject;

    const media = async (media, audio, [width, height] = []) => await navigator.mediaDevices[`get${media}Media`]({
        audio, video: width && height ? { width: { ideal: width }, height: { ideal: height } } : false
    });

    const showAlert = text => {
        if (!alertText) {
            setAlertText(text);
            setTimeout(() => setAlertText(''), 5000);
        }
    };

    const handle = {
        drop: () => {
            setAudio(false);
            setAudioPerson(false);
            setVideo(false);
            setVideoPerson(false);
            setScreen(false);
            setScreenPerson(false);

            setAudioPersonVolume(0);
            src('stream', null);
            setEnded(true);
        },

        end: () => {
            socket.emit('end');
            handle.drop();
        },

        auth: auth => auth ? setAuth(true) : setNotAuthed(true),

        audio: async () => {
            setAudioButtonEnabled(false);
            if (audioTrack) audioTrack.stop();

            if (audio) {
                let audioStream;

                try {
                    audioStream = await media('User', true);
                } catch (err) {
                    setAudio(false);
                    showAlert('Unable to use microphone');
                    return;
                }

                const audioTrack = audioStream.getAudioTracks()[0];

                if (userStreamPerson) try {
                    peer.addTrack(audioTrack, userStream);
                } catch (err) {
                    audioTrack.stop();
                    debug.log('cannot add audio track:\n', err);
                    setAudio(false);
                    return;
                }

                setAudioTrack(audioTrack);
            } else if (audioTrack) {
                if (userStreamPerson) try {
                    peer.removeTrack(audioTrack, userStream);
                } catch (err) {
                    debug.log('cannot remove audio track:\n', err);
                }

                setAudioTrack(null);
            }

            if (userStreamPerson) socket.emit('audio', audio);
            setAudioButtonEnabled(true);
        },

        video: async () => {
            setVideoButtonEnabled(false);
            if (videoTrack) videoTrack.stop();

            if (video) {
                let videoStream;

                try {
                    videoStream = await media('User', false, [1920, 1080]);
                } catch (err) {
                    setVideo(false);
                    showAlert('Unable to use camera');
                    return;
                }

                src('reflex', videoStream);

                const videoTrack = videoStream.getVideoTracks()[0];
                const ratio = (settings => settings.height / settings.width)(videoTrack.getSettings());
                setReflexResolution([defaultHeight / ratio, defaultHeight]);

                if (userStreamPerson) try {
                    peer.addTrack(videoTrack, userStream);
                } catch (err) {
                    videoTrack.stop();
                    debug.log('cannot add video track:\n', err);
                    setVideo(false);
                    return;
                }

                setVideoTrack(videoTrack);
            } else if (videoTrack) {
                if (userStreamPerson) try {
                    peer.removeTrack(videoTrack, userStream);
                } catch (err) {
                    debug.log('cannot remove video track:\n', err);
                }

                setVideoTrack(null);
            }

            if (userStreamPerson) socket.emit('video', video);
            setVideoButtonEnabled(true);
        },

        screen: async () => {
            setScreenButtonEnabled(false);
            if (displayStream) displayStream.getTracks().map(track => track.stop());

            if (screen) {
                let displayStream;

                try {
                    displayStream = await media('Display', true, resolution);
                } catch (err) {
                    setScreen(false);
                    showAlert('Unable to share screen');
                    return;
                }

                src('screen', displayStream);

                const videoTrack = displayStream.getVideoTracks()[0];
                const ratio = (settings => settings.height / settings.width)(videoTrack.getSettings());
                setScreenResolution([defaultHeight / ratio, defaultHeight]);

                videoTrack.onended = () => setScreen(false);

                try {
                    peer.addStream(displayStream);
                } catch (err) {
                    displayStream.getTracks().map(track => track.stop());
                    debug.log('cannot add stream:\n', err);
                    setScreen(false);
                    return;
                }

                setDisplayStream(displayStream);
            } else if (displayStream) {
                try {
                    peer.removeStream(displayStream);
                } catch (err) {
                    debug.log('cannot remove display stream:\n', err);
                }

                setDisplayStream(null);
            }

            socket.emit('screen', screen);
            setScreenButtonEnabled(true);
        },

        move: () => {
            setShow(true);
            setMouse(Date.now());
        },

        hide: () => {
            const show = !hide;
            if (show) handle.move();
            setHide(show);
        },

        full: e => {
            e.preventDefault();
            full ? document.exitFullscreen() : document.body.requestFullscreen();
        },

        reload: e => {
            e.preventDefault();
            socket.emit('reload');
            setReload(true);
        },

        terminal: {
            toggle: e => {
                e.preventDefault();
                setToggleTerminal(true);
            },

            enter: e => {
                e.preventDefault();
                setEnter(true);
            },

            scroll: () => {
                const { scrollTop, clientHeight, scrollHeight } = terminalDisplayRef.current;
                setScrolled(scrollTop + clientHeight + 10 > scrollHeight);
            },

            type: e => setMessageText(e.target.value),

            close: (_, reason) => reason == 'escapeKeyDown' && setTerminalDialogOpen(false)
        },

        cover: () => setObjectFit(['cover', 'contain'][Number(objectFit == 'cover')]),

        resolutionDialog: {
            cancel: () => {
                setSelectedResolution(resolution.join(' x '));
                setResolutionDialogOpen(false);
            },

            confirm: () => {
                window.localStorage.setItem('resolution', selectedResolution);
                setResolution(selectedResolution.split(' x '));
                setResolutionDialogOpen(false);
            },

            close: (_, reason) => reason == 'escapeKeyDown' && handle.resolutionDialog.cancel()
        },

        interceptDialog: {
            cancel: () => {
                socket.emit('intercept', false);
                setInterceptDialogOpen(false);
                setNotIntercepted(true);
            },

            confirm: () => {
                socket.emit('intercept', true);
                setInterceptDialogOpen(false);
                setAuth(true);
            }
        },

        caller: {
            onMouseDown: () => {
                document.addEventListener('mousemove', handle.caller.onMouseMove);
                document.addEventListener('mouseup', handle.caller.onMouseUp);
                document.body.style.cursor = 'ns-resize';
            },

            onMouseMove: e => {
                let height = e.clientY - 11;
                if (height < defaultHeight) height = defaultHeight;
                setCallerResolution([height / callerRatio, height]);
            },

            onMouseUp: () => {
                document.removeEventListener('mousemove', handle.caller.onMouseMove);
                document.removeEventListener('mouseup', handle.caller.onMouseUp);
                document.body.style.cursor = 'default';
            }
        },

        buzz: () => {
            if (userStreamPerson && buzzEnabled) {
                setBuzzEnabled(false);
                setTimeout(() => setBuzzEnabled(true), buzzTimeout);
                socket.emit('buzz');
            }
        },

        handshake: () => {
            if (userStreamPerson && handshakeEnabled) {
                setHandshakeEnabled(false);
                setTimeout(() => setHandshakeEnabled(true), handshakeTimeout);
                socket.emit('handshake');
            }
        }
    };

    const screenResolutionList = [];

    for (const aspectRatio in resolutionList) {
        screenResolutionList.push(<ListSubheader style={style.bgc('dimgray', 'navajowhite')}>{aspectRatio}</ListSubheader>);

        screenResolutionList.push(...resolutionList[aspectRatio].map(resolution => {
            const stringifiedResolution = resolution.join(' x ');
            const selected = stringifiedResolution == selectedResolution;

            return <ListItemButton dense style={selected ? style.bgc('forestgreen', 'greenyellow') : style.bgc('darkgreen', 'lime')} selected={selected} onClick={() => setSelectedResolution(stringifiedResolution)}>
                <ListItemText primary={stringifiedResolution}/>
            </ListItemButton>;
        }));
    }

    const ResolutionDialog = <>
        <Dialog PaperProps={{ style: style.color.default }} fullWidth open={resolutionDialogOpen} onClose={handle.resolutionDialog.close}>
            <DialogTitle style={style.center()}>Screen Resolution</DialogTitle>
            <div style={style.flex('center')}>
                <List style={{ ...style.padding.tb(0), ...style.scroll(300), ...style.width.min(300) }}>{screenResolutionList}</List>
            </div>
            {br(20)}
            <div style={style.flex('space-around')}>
                {btn(handle.resolutionDialog.cancel, 'cancel', style.color.red)}
                {btn(handle.resolutionDialog.confirm, 'confirm', style.color.green.light)}
            </div>
        </Dialog>
    </>;

    const InterceptDialog = <>
        <Dialog PaperProps={{ style: style.color.default }} fullWidth open={interceptDialogOpen}>
            <DialogTitle style={style.center()}>You are about to intercept the ongoing call. Proceed?</DialogTitle>
            <div style={style.flex('space-around')}>
                {btn(handle.interceptDialog.cancel, 'cancel', style.color.red)}
                {btn(handle.interceptDialog.confirm, 'confirm', style.color.red)}
            </div>
        </Dialog>
    </>;

    const TerminalDialog = <>
        <Dialog PaperProps={{ style: style.bgc('rgba(20, 20, 20, 0.7)') }} fullWidth open={terminalDialogOpen} onClose={handle.terminal.close}>
            <div style={style.flex('flex-end')}>
                <div style={{ ...style.color.halfBlack, ...style.ptr }} onClick={() => setTerminalDialogOpen(false)}><CloseTwoTone htmlColor="orangered"/></div>
            </div>
            <textarea style={style.textarea} rows={20} value={chatText} onScroll={handle.terminal.scroll} ref={terminalDisplayRef} disabled/>
            <div style={style.flex('flex-end')}>
                <div style={{ ...style.color.halfBlack, ...(!scrolled && style.ptr) }} onClick={scrollToEnd}><ArrowDownwardTwoTone htmlColor={scrolled ? 'gray' : 'limegreen'}/></div>
            </div>
            {divider(true, 'forestgreen')}
            <textarea style={style.textarea} rows={5} value={messageText} onChange={handle.terminal.type} placeholder="Write a message..." autoFocus/>
            <div style={style.flex('flex-end')}>
                <div style={{ ...style.color.halfBlack, ...(messageText.trim() && style.ptr) }} onClick={() => setEnter(true)}><SendTwoTone htmlColor={messageText.trim() ? 'limegreen' : 'gray'}/></div>
            </div>
        </Dialog>
    </>;

    useEffect(() => {
        if (latestMessage) {
            const { text, send } = latestMessage;
            setChatText(`${chatText}${send ? '>>>' : '<<<'} ${text}\n\n`);
        }
    }, [latestMessage]);

    useEffect(() => {
        if (scrolled || latestMessage.send) scrollToEnd();
    }, [chatText]);

    useEffect(() => {
        if (toggleTerminal) {
            setTerminalDialogOpen(!terminalDialogOpen);
            setToggleTerminal(false);
        }
    }, [toggleTerminal]);

    useEffect(() => {
        if (enter) {
            const text = messageText.trim();

            if (terminalDialogOpen && text) {
                chatSocket.emit('message', { text, userName });
                setLatestMessage({ text, send: true });
                setMessageText('');
            }

            setEnter(false);
        }
    }, [enter]);

    useEffect(() => {
        if (receive) {
            const { text, userName: UN } = receive;
            if (UN == userName) setLatestMessage({ text });
            setReceive(null);
        }
    }, [receive]);

    useEffect(() => {
        terminalDisplayRef.current ? terminalDialogOpen ? terminalDisplayRef.current.scrollTop = currentScroll : setCurrentScroll(terminalDisplayRef.current.scrollTop) : setWaitTerminalLoadEnd(!waitTerminalLoadEnd);
    }, [terminalDialogOpen, waitTerminalLoadEnd]);

    useEffect(...getReady(setReady));

    useEffect(() => {
        if (ready && !creds.userName) handle.auth(false);
    }, [ready]);

    useEffect(() => {
        if (creds.userName) {
            chatSocket.on('auth', auth => auth || setNotAuthed(true));
            chatSocket.on('message', setReceive);
            chatSocket.emit('auth', creds);

            socket.on('end', handle.drop);
            socket.on('auth', handle.auth);
            socket.on('intercept', () => setInterceptDialogOpen(true));
            socket.on('intercepted', () => setIntercepted(true));
            socket.on('audio', setAudioPerson);
            socket.on('video', setVideoPerson);
            socket.on('screen', setScreenPerson);
            socket.on('decline', () => setDeclined(true));

            socket.on('buzz', () => {
                if (!buzz) {
                    setBuzz(true);
                    setTimeout(() => setBuzz(false), buzzTimeout * 0.75);
                }
            });

            socket.on('handshake', () => {
                if (!opacity) {
                    setScale(danceScale);
                    setOpacity(1);
                    setTimeout(() => { setScale(initScale); setOpacity(0); }, handshakeTimeout * 0.75);
                }
            });

            socket.emit('auth', { creds, userName });

            document.addEventListener('keydown', e => {
                switch (e.code) {
                case 'F1':
                    handle.terminal.toggle(e);
                    break;
                case 'F5':
                    handle.reload(e);
                    break;
                case 'F11':
                    handle.full(e);
                    break;
                case 'Enter':
                    handle.terminal.enter(e);
                    break;
                default:
                    break;
                }
            });

            document.addEventListener('mousemove', handle.move);

            setInterval(() => {
                const now = new Date();
                setNow(now.getTime());
                setLocalTime(`${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`);
                setFull(Boolean(document.fullscreenElement));
            }, 250);

            (async () => {
                const person = await api.get.one(userName);
                person ? setPerson(person) : setNotFound(true);
            })();
        }
    }, [creds]);

    useEffect(() => {
        const silent = createSilentAudioTrack();

        const initPeer = async () => {
            const userStream = new MediaStream([silent]);
            setUserStream(userStream);

            const iceServers = await api.get.iceServers(creds);
            const peer = new Peer({ config: { iceServers, iceTransportPolicy: 'all', iceCandidatePoolSize: 10 }, channelConfig: { ordered: true, maxRetransmits: 10 }, initiator: true, stream: userStream });

            let connecting = true;
            peer.on('stream', stream => {
                if (connecting) {
                    connecting = false;
                    src('caller', stream);
                    setUserStreamPerson(stream);
                } else {
                    setDisplayAudioPerson(Boolean(stream.getAudioTracks().length));
                    setDisplayStreamPerson(stream);
                }
            });

            peer.on('signal', signal => socket.emit('call', signal));

            let canSignal = true;
            socket.on('signal', signal => {
                try {
                    peer.signal(signal);
                } catch (err) {
                    if (canSignal) {
                        canSignal = false;
                        debug.log('cannot signal:\n', err);
                        initPeer();
                    }
                }
            });

            setPeer(peer);
        };

        if (auth) initPeer();
    }, [auth]);

    useEffect(() => {
        if (userStreamPerson && !ended) {
            const diff = now - startTime;
            setTimer((diff - diff % 1000) / 1000);
        }

        if (show && mouse + 5000 < now) setShow(false);
    }, [now]);

    useEffect(() => {
        setDone(ended || declined || notFound || notAuthed || intercepted || notIntercepted);
    }, [ended, declined, notFound, notAuthed, intercepted, notIntercepted]);

    useEffect(() => {
        if (done) {
            socket.disconnect();
            setTimeout(() => window.close(), 5000);
        }
    }, [done]);

    useEffect(() => {
        if (reload) window.location.reload();
    }, [reload]);

    useEffect(() => {
        setCursor(done || show || !hide ? null : 'none');
    }, [done, show, hide]);

    useEffect(() => {
        setDisplay(done ? 'none' : cursor);
    }, [done, cursor]);

    useEffect(() => {
        if (userStreamPerson && !startTime) setStartTime(Date.now());
    }, [userStreamPerson]);

    useEffect(() => {
        handle.audio();
    }, [userStreamPerson, audio]);

    useEffect(() => {
        handle.video();
    }, [userStreamPerson, video]);

    useEffect(() => {
        if (userStreamPerson) handle.screen();
    }, [userStreamPerson, screen]);

    useEffect(() => {
        if (userStreamPerson && !screenPerson) src('stream', userStreamPerson);
    }, [userStreamPerson, screenPerson]);

    useEffect(() => {
        if (displayStreamPerson && screenPerson) src('stream', displayStreamPerson);
    }, [displayStreamPerson, screenPerson]);

    useEffect(() => {
        if (userStreamPerson && audioPerson) {
            const interval = setInterval(() => {
                const voice = userStreamPerson.getAudioTracks()[1];
                if (!voice) return;
                src('voice', new MediaStream([voice]));
                clearInterval(interval);
            }, 250);
        }
    }, [audioPerson]);

    useEffect(() => {
        if (userStreamPerson && videoPerson) {
            const interval = setInterval(() => {
                const videoTrack = userStreamPerson.getVideoTracks()[0];
                if (!videoTrack) return;
                const ratio = (settings => settings.height / settings.width)(videoTrack.getSettings());
                if (!ratio) return;
                setCallerRatio(ratio);
                setCallerResolution([defaultHeight / ratio, defaultHeight]);
                clearInterval(interval);
            }, 250);
        }
    }, [videoPerson]);

    useEffect(() => {
        if (displayAudioPerson && !screenPerson) setDisplayAudioPerson(false);
    }, [screenPerson]);

    useEffect(() => {
        document.getElementById('voice').volume = audioPersonVolume / 100;
    }, [audioPersonVolume]);

    useEffect(() => {
        document.getElementById('stream').volume = screenPersonVolume / 100;
    }, [screenPersonVolume]);

    const buzzAudio = <audio autoPlay>
        <source src={`${process.env.PUBLIC_URL}/audio/Buzz1.wav`} type="audio/wav"/>
    </audio>;

    const doneAudio = <audio autoPlay>
        <source src={`${process.env.PUBLIC_URL}/audio/PhoneBusy.wav`} type="audio/wav"/>
    </audio>;

    const notAuthedAudio = <audio autoPlay>
        <source src={`${process.env.PUBLIC_URL}/audio/PhoneVoice${randomNum(4)}.wav`} type="audio/wav"/>
    </audio>;

    const dialtoneAudio = <audio autoPlay loop>
        <source src={`${process.env.PUBLIC_URL}/audio/dial-tone.mp3`} type="audio/mpeg"/>
    </audio>;

    document.body.style.overflow = 'hidden';

    window.onbeforeunload = () => {
        if (done || reload) return;
        handle.end();
        return true;
    };

    return <>
        <div style={{ ...style.flex('center'), ...style.abs.center }}>
            <div style={{ ...style.flex(), flexWrap: 'nowrap', transition: `transform ${transition}, opacity ${transition}`, transform: `scale(${scale})`, opacity, zIndex: 100 }}>
                {Array(5).fill(null).map(_ => <img src={`${process.env.PUBLIC_URL}/penguin.png`}/>)}
            </div>
        </div>
        {ResolutionDialog}
        {InterceptDialog}
        {TerminalDialog}
        <audio autoPlay id="voice"/>
        {buzz ? buzzAudio : <></>}
        {done ? notAuthed ? notAuthedAudio : doneAudio : <></>}
        {userStreamPerson || done ? <></> : dialtoneAudio}
        <div style={{ cursor }}>
            <video autoPlay id="stream" muted={!screenPerson} style={style.abs.bgc('black', objectFit)}/>
            {videoPerson || screenPerson ? <></> : [
                <div style={style.abs.bgc('black', 'cover')}/>,
                absCenter(ended ? <Typography variant="h5" style={style.clr('limegreen')}>{'Call ended'}{br(30)}{timer ? clock(timer) : 'without response'}</Typography> : declined ? 'Call declined' : notFound ? 'User not found' : notAuthed ? 'Authentication failed' : intercepted ? 'Call intercepted' : notIntercepted ? 'Ongoing call not intercepted' : <Avatar style={style.margin.ava} src={avaPath(person.id)}/>, 'orangered')
            ]}
        </div>
        <div style={{ ...style.video(1, 0, callerResolution), display: !(videoPerson && screenPerson) ? 'none' : (!callerVisible && display) }}>
            <video autoPlay id="caller" muted style={{ width: '100%', height: '100%', objectFit: 'contain' }}/>
            <div onMouseDown={handle.caller.onMouseDown} style={{ height: 5, cursor: 'ns-resize', opacity: 0.5, ...style.bgc('forestgreen') }}/>
        </div>
        <div style={{ display }}>
            {videoPerson && screenPerson && hide ? callerVisible ? <VisibilityTwoTone onClick={() => setCallerVisible(false)} style={{ right: 0, top: 0, position: 'fixed', ...style.mg, ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/> : <VisibilityOffTwoTone onClick={() => setCallerVisible(true)} style={{ right: 0, top: 0, position: 'fixed', ...style.mg, ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/> : <></>}
            <video autoPlay id="screen" muted style={{ ...style.video(0, 1, screenResolution), display: !screen && 'none' }}/>
            <video autoPlay id="reflex" muted style={{ ...style.video(1, 1, reflexResolution), display: !video && 'none' }}/>
            <div style={{ position: 'fixed', left: 0, top: 0, padding: 5 }}>
                <Typography variant="h6" style={{ ...style.bd, ...style.color.green.dark }}>
                    {ended ? <CallEndTwoTone/> : userStreamPerson ? audioPerson ? <MicTwoTone/> : <MicOffTwoTone/> : <RingVolumeTwoTone/>} {strStart(person.firstName, 10)} {strStart(person.lastName, 15)}
                    <center>
                        <Button color="inherit" variant="text" onClick={handle.cover} style={{ ...style.color.green.light, ...style.width.min(100) }}>{objectFit}</Button>
                        <Button color="inherit" variant="text" onClick={handle.hide} style={{ ...style.color.green.light, ...style.width.min(80) }}>{hide ? 'hide' : 'show'}</Button>
                    </center>
                    <center>{timer ? clock(timer) : 'Calling...'}</center>
                </Typography>
                <Stack spacing={2} direction="row" style={style.margin.lr(20)}>
                    {audioPersonVolume ? audioPersonVolume > 50 ? <VolumeUpTwoTone onClick={() => setAudioPersonVolume(0)} style={{ ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/> : <VolumeDownTwoTone onClick={() => setAudioPersonVolume(0)} style={{ ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/> : <VolumeOffTwoTone onClick={() => setAudioPersonVolume(100)} style={{ ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/>}
                    <Slider sx={style.sx} size="small" value={audioPersonVolume} onChange={e => setAudioPersonVolume(e.target.value)} aria-label="Small" valueLabelDisplay="auto"/>
                    <MicTwoTone style={style.color.halfBlack} htmlColor={audioPerson ? 'limegreen' : 'gray'}/>
                </Stack>
                <Stack spacing={2} direction="row" style={{ ...style.margin.lr(20), display: !screenPerson && 'none' }}>
                    {screenPersonVolume ? screenPersonVolume > 50 ? <VolumeUpTwoTone onClick={() => setScreenPersonVolume(0)} style={{ ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/> : <VolumeDownTwoTone onClick={() => setScreenPersonVolume(0)} style={{ ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/> : <VolumeOffTwoTone onClick={() => setScreenPersonVolume(100)} style={{ ...style.ptr, ...style.color.halfBlack }} htmlColor={'limegreen'}/>}
                    <Slider sx={style.sx} size="small" value={screenPersonVolume} onChange={e => setScreenPersonVolume(e.target.value)} aria-label="Small" valueLabelDisplay="auto"/>
                    <ScreenShareTwoTone style={style.color.halfBlack} htmlColor={displayAudioPerson ? 'limegreen' : 'gray'}/>
                </Stack>
            </div>
            <center style={{ ...style.flex('center', 'flex-end'), position: 'absolute', bottom: 10, left: 0, right: 0, margin: 'auto' }}>
                <div style={{ ...style.column, ...style.flex('center', 'center') }}>
                    <div style={{ ...style.color.halfBlack, ...(!screen && style.ptr) }} onClick={() => !screen && setResolutionDialogOpen(true)}><HdTwoTone htmlColor={screen ? 'gray' : 'limegreen'}/></div>
                    <Fab style={userStreamPerson && screenButtonEnabled ? style.color.green.light : { ...style.color.gray, cursor: 'default' }} onClick={() => userStreamPerson && screenButtonEnabled && setScreen(!screen)}>{screen ? <ScreenShareTwoTone/> : <StopScreenShareTwoTone/>}</Fab>
                </div>
                <div style={{ ...style.column, ...style.flex('center', 'center') }}>
                    <Typography variant="h6" style={{ ...style.color.halfBlack, ...style.clr('limegreen'), ...style.padding.lr(5), display: !full && 'none' }}>{localTime}</Typography>
                    <div>
                        <Fab style={style.color.green.light} onClick={handle.full}>{full ? <FullscreenExitTwoTone/> : <FullscreenTwoTone/>}</Fab>
                        <Fab style={style.color.red} onClick={handle.end}><CallEndTwoTone/></Fab>
                    </div>
                </div>
                <div style={{ ...style.column, ...style.flex('center', 'center') }}>
                    <div style={{ ...style.color.halfBlack, ...(userStreamPerson && buzzEnabled && style.ptr) }} onClick={handle.buzz}><NotificationsActiveTwoTone htmlColor={userStreamPerson && buzzEnabled ? 'limegreen' : 'gray'}/></div>
                    <Fab style={audioButtonEnabled ? style.color.green.light : style.color.gray} onClick={() => audioButtonEnabled && setAudio(!audio)}>{audio ? <MicTwoTone/> : <MicOffTwoTone/>}</Fab>
                </div>
                <div style={{ ...style.column, ...style.flex('center', 'center') }}>
                    <div style={{ ...style.color.halfBlack, ...(userStreamPerson && handshakeEnabled && style.ptr) }} onClick={handle.handshake}><HandshakeTwoTone htmlColor={userStreamPerson && handshakeEnabled ? 'limegreen' : 'gray'}/></div>
                    <Fab style={videoButtonEnabled ? style.color.green.light : style.color.gray} onClick={() => videoButtonEnabled && setVideo(!video)}>{video ? <VideocamTwoTone/> : <VideocamOffTwoTone/>}</Fab>
                </div>
            </center>
        </div>
        {alertText ? <center style={{ ...style.flex('center'), position: 'absolute', top: 10, left: 0, right: 0, margin: 'auto' }}>
            <Alert variant="outlined" severity="error" color="info" style={style.bgc('black', 'orangered')}>{alertText}</Alert>
        </center> : <></>}
    </>;
};
